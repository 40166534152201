<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <section class="ticket_info">
        <div class="container">
          <div
            class="row justify-content-center shadow-lg bg-white p-5 rounded"
          >
            <div class="col-md-12">
              <div class="row screenshot-row align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="row inner-row">
                    <div class="col-md-12">
                      <router-link to="/support/ticket-list">
                        <h4 class="fw-bold m-0 mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            style="fill: rgba(73, 80, 87, 1)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
                            />
                          </svg>
                          Ticket Info
                        </h4>
                      </router-link>
                    </div>

                    <div class="col-md-12">
                      <label class="fw-bold"
                        >Name:
                        <span class="fw-normal text-capitalize">{{
                          ticketData.author_name
                        }}</span></label
                      >
                    </div>
                    <!-- Name  -->
                    <div class="col-md-12 mb-3">
                      <label class="fw-bold"
                        >Status:
                        <span
                          :style="status(ticketData.status)"
                          class="fw-normal text-capitalize px-2 py-1 rounded"
                          >{{ ticketData.status }}</span
                        ></label
                      >
                    </div>
                    <!-- Status  -->
                  </div>
                  <!--row inner-row-->
                </div>
                <!--col-xl-4 col-lg-4 col-md-4-->

                <div class="col-xl-8 col-lg-8 col-md-8">
                  <div class="row screen-inner-row align-items-center">
                    <div
                      class="col-xl-3 col-lg-3 col-md-3"
                      v-for="(item, index) of images"
                      :key="index"
                    >
                      <div class="screenshot-image">
                        <img :src="item" alt="screenshot" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <!--row screen-inner-row-->
                </div>
                <!--col-xl-8 col-lg-8 col-md-8-->
              </div>
              <!--row screenshot-row-->

              <div class="row">
                <div class="col-md-12 mb-3">
                  <label class="fw-bold">Comments: </label>
                  <div
                    class="previous_data border border-grey"
                    v-if="ticketData.comments != ''"
                  >
                    <div
                      class="previous_chat_data border border-grey d-flex mb-2"
                      v-for="(data, index) in ticketData.comments"
                      :key="index"
                    >
                      <div class="user_img">
                        <i class="fas fa-user-tie fs-1 me-2"></i>
                      </div>
                      <div class="user_date_comment_box">
                        <div
                          class="user_date_box d-flex justify-content-between"
                        >
                          <span class="fw-bold">{{ data.user.name }}</span>
                          <p class="m-0">
                            {{
                              new Date(data.created_at).toLocaleDateString(
                                "en-US",
                                options
                              )
                            }}
                          </p>
                        </div>
                        <div class="user_name_comment">
                          <p class="mb-2">{{ data.comment }}</p>
                          <img
                            :src="data.image"
                            alt="image"
                            style="height: 80px; width: 100px"
                            v-if="data.image != ''"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 1  -->
                  </div>
                  <div
                    class="
                      d-flex
                      justify-content-center
                      border border-grey
                      mb-2
                      previous_chat_data
                    "
                    v-else
                  >
                    No Comment Available
                  </div>
                </div>
                <!-- Previous Data  -->
                <form @submit.prevent="submitCmnt">
                  <div
                    class="col-md-12 mb-3"
                    v-if="ticketData.status != 'close'"
                  >
                    <label class="fw-bold">Comment</label>
                    <textarea
                      class="form-control shadow-none"
                      placeholder="Leave a comment here"
                      style="height: 100px"
                      v-model.trim="$v.form.comment.$model"
                    ></textarea>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.comment.required"
                    >
                      Comment is Required
                    </div>
                    <div class="form-control shadow-none my-3">
                      <input
                        type="file"
                        class="form-control"
                        accept="image/*"
                        @change="uploadImage($event)"
                        id="fileupload"
                      />
                    </div>
                  </div>
                  <!-- Input field  -->
                  <div
                    class="col-md-12 text-center"
                    v-if="ticketData.status != 'close'"
                  >
                   <button v-if="submit_load" class="btn btn-outline-dark col-5 col-md-4 col-lg-3 col-xl-2" type="button">
                        <div class="spinner-border" role="status" style="width: 1.2rem;height: 1.2rem;">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                    <button v-else type="submit" class="btn btn-outline-dark col-5 col-md-4 col-lg-3 col-xl-2">
                      Submit
                    </button>
                     
                  </div>
                </form>
                <!-- Button  -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </div>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      submit_load: false,
      back: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: rgba(255, 255, 255, 1);""><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path></svg>',
      url: "",
      title: "View Ticket",
      items: [
        {
          text: "Ticket List",
          // href: "/support/ticket-list",
        },
        {
          text: "View Ticket",
          active: true,
        },
      ],
      submitted: false,
      form: {
        comment: "",
        ticket_id: this.$route.params.slug,
      },
      file: "",
      // current_id: this.$route.params.slug,
      ticketData: {},
      images: [],
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },

  mounted() {
    this.getTicket();
    this.url = ApiClass.baseUrl.slice(0, -4);
  },

  methods: {
    uploadImage(e) {
      this.file = e.target.files[0];
    },
    status(change) {
      if (change == "open") {
        return {
          color: "#256029",
          backgroundColor: "#c8e6c9",
        };
      } else if (change == "close") {
        return {
          color: "#c63737",
          backgroundColor: "#ffcdd2",
        };
      } else if (change == "inprogress") {
        return {
          color: "#805b36",
          backgroundColor: "#ffd8b2",
        };
      }
    },
    async getTicket() {
      var result = await ApiClass.getRequest(
        "admin/ticket/get/" + this.form.ticket_id,
        true
      );
    
      if (result.data.status_code == 1) {
        this.ticketData = result.data.data;
        this.images = result.data.data.images;
        console.log({imagesss:this.images});
      }
    },
    async submitCmnt() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      this.submitted = false;
      this.submit_load = true;
      let form_data = new FormData();
      form_data.append("ticket_id", this.form.ticket_id);
      form_data.append("image", this.file);
      form_data.append("comment", this.form.comment);
       this.submit_load = false
      // const headers = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      // await ApiClass.postRequest(
      //   "ticket_comment/create",
      //   true,
      //   form_data,
      //   headers
      // ).then((res) => {
      //   if (res.data.status_code == 1) {
      //     this.submit_load = false;
      //     this.swal("success", res.data.message);

      //     this.getTicket();
      //     this.form.comment = "";
      //     let img = document.getElementById('fileupload');
      //     img.value =null;
      //   } else {
      //     this.swal("error", res.data.message);
      //     this.submit_load = false;
      //   }
      // });
   
      await ApiClass.postRequest("ticket_comment/create", true, form_data).then((res) => {
               
                    if (res.data.status_code == 1) {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                    });
                   this.getTicket();
                   this.form.comment ='';
                        
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }
                })

    },
  },
};
</script>


<style scoped>
    section.ticket_info {
        padding: 50px 0;
    }

    .previous_data {
        padding: 10px 10px;
        border-radius: 5px;
        height: 200px;
        overflow-y: auto;
    }

    .previous_chat_data {
        padding: 10px 10px;
        border-radius: 5px;
        
    }
    .border-grey{
        border-color: #ced4da !important;
    }
    .user_date_comment_box{
        width: 100%;
    }
</style>